<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Notifications</div>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="conOfHeader text-right">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="dialogProject = true">
              <v-icon>mdi-plus</v-icon>
              Push New Notification
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-title class="statusTxt">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <div class="badge badge-primary">
                  {{ item.status == true ? "SENT" : "WAITING" }}
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-dialog scrollable v-model="dialogProject" max-width="800">
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="6">
                <label class="eachLabel">Title</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Notification Title"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.title"
                  :error-messages="titleErrors"
                  @input="$v.formItem.title.$touch()"
                  @blur="$v.formItem.title.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="eachLabel">Body</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Notification Body"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.body"
                  :error-messages="bodyErrors"
                  @input="$v.formItem.body.$touch()"
                  @blur="$v.formItem.body.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="eachLabel">Scheduled at</label>
                <v-text-field
                  type="datetime-local"
                  outlined
                  solo
                  v-model="formItem.scheduled_at"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="eachLabel">Topic</label>
                <v-select
                  outlined
                  solo
                  :items="this.topics"
                  v-model="formItem.topics"
                  :error-messages="topicsErrors"
                  @input="$v.formItem.topics.$touch()"
                  @blur="$v.formItem.topics.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <label class="eachLabel">Click Action</label>
                <v-select
                  outlined
                  :items="['UPDATE', 'RESULT', 'NEWS']"
                  v-model="formItem.click_action"
                  :error-messages="click_actionErrors"
                  @input="$v.formItem.click_action.$touch()"
                  @blur="$v.formItem.click_action.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <label class="eachLabel">Push Time</label>
                <v-checkbox
                  outlined
                  solo
                  :label="`Push Now`"
                  v-model="formItem.push_now"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";

let apiServices = new ApiService();
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EmptyState from "@/modules/shared/components/emptystate";
import moment from "moment";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        title: { required },
        body: { required },
        topics: { required },
        click_action: { required },
      },
    };
    return validations;
  },
  data: () => ({
    dialogProject: false,
    isLoadingSave: false,
    isLoading: false,
    editedIndex: -1,
    pagination: {
      current_page: 1,
    },
    formItem: {},
    defaultItem: {},
    headers: [
      {
        text: "TOPICS",
        align: "start",
        value: "topics",
        sortable: true,
      },
      { text: "CLICK ACTION", value: "click_action" },
      { text: "TITLE", value: "title" },
      { text: "BODY", value: "body" },
      { text: "SCHEDULED AT", value: "scheduled_at" },
      { text: "STATUS", value: "status" },
      { text: "CREATED BY", value: "user.name" },
      { text: "CREATE AT", value: "created_at" },
      { text: "ACTION", value: "actions", sortable: false, align: "right" },
    ],
    listData: [],
  }),
  components: { EmptyState },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Push New Notification"
        : "Edit Notification";
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.formItem.title.$dirty) return errors;
      !this.$v.formItem.title.required && errors.push("Title Is Required.");
      return errors;
    },
    bodyErrors() {
      const errors = [];
      if (!this.$v.formItem.body.$dirty) return errors;
      !this.$v.formItem.body.required && errors.push("Body Is Required.");
      return errors;
    },
    topicsErrors() {
      const errors = [];
      if (!this.$v.formItem.topics.$dirty) return errors;
      !this.$v.formItem.topics.required && errors.push("Topics Is Required.");
      return errors;
    },
    click_actionErrors() {
      const errors = [];
      if (!this.$v.formItem.click_action.$dirty) return errors;
      !this.$v.formItem.click_action.required &&
        errors.push("Click Action Is Required.");
      return errors;
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = item.id;
      let editFormItem = {
        title: item.title,
        body: item.body,
        scheduled_at: item.scheduled_at,
        topics: item.topics,
        click_action: item.click_action,
        push_now: item.push_now,
      };
      this.formItem = editFormItem;
      this.dialogProject = true;
    },
    getTopicsData() {
      this.topics = [];
      this.isLoading = true;
      apiServices.get(`notification/topics`).then((res) => {
        if (res) {
          this.topics = res.data;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    itemEdited() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;

      apiServices
        .update(this.editedIndex, "notification", this.formItem)
        .then((res) => {
          if (res) {
            this.pagination.current_page = 1;
            this.getListData(this.pagination.current_page);
            this.isLoadingSave = false;
            this.closeProjectModal();
          } else {
            this.isLoadingSave = false;
          }
        });
    },
    itemAddNew() {
      this.$v.$touch();
      moment(this.formItem.scheduled_at).format("Y-m-d H:i");
      console.log(this.formItem.scheduled_at);
      if (this.$v.$error) return;
      this.isLoadingSave = true;
      apiServices.post("notification", this.formItem).then((res) => {
        if (res) {
          this.pagination.current_page = 1;
          this.getListData(this.pagination.current_page);
          this.isLoadingSave = false;
          this.closeProjectModal();
        } else {
          this.isLoadingSave = false;
        }
      });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    closeProjectModal() {
      this.editedIndex = -1;
      this.dialogProject = false;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      apiServices.get(`notification?per_page=7&page=${page}`).then((res) => {
        if (res) {
          console.log('res', res)
          this.listData = res.data;
          this.pagination = res.meta;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
    this.getTopicsData();
  },
  watch: {
    dialogProject(val) {
      val || this.closeProjectModal();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_notifications.scss";
</style>
